body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.modal-footer {
    z-index: 111111;
}

.onboarding-modal {
    width: 750px;
    max-width:750px!important;
}

.btn-green {
    color: white !important;
}
.text-yellow {
    display: none;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__message--no-data {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.react-pdf__message--loading {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.pointer-events-none {
    pointer-events: none;
}

input[name="currencyType"] {
    width: 60px;
}

.close span {
    display:none;
}

.qr-preview-right {
    right: 7px;
}

.qr-preview-left {
    left: 7px;
}

.subscription-reactivation-button {
    width: 20%;
}

.subscription-reactivation-banner .card-title {
    color: #cd201f;
    -webkit-animation: blinker 1.3s linear infinite;
            animation: blinker 1.3s linear infinite;
}

@-webkit-keyframes blinker {
    from,
    49.9% {
        opacity: 0;
    }
    50%,
    to {
        opacity: 1;
    }
}

@keyframes blinker {
    from,
    49.9% {
        opacity: 0;
    }
    50%,
    to {
        opacity: 1;
    }
}

.billing-type-toggle {
    text-align: center;
    padding-bottom: 40px;
}

.billing-type-yearly-arrow {
    position: absolute;
    top: -170%;
}
